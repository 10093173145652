import React, { Component } from "react";
import { Row, Col, Card, Form, CardBody, CardTitle, Container, Button } from "reactstrap";
import Dropzone from "react-dropzone";

import { Redirect } from 'react-router'

// Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { Link } from "react-router-dom";

class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.handleAcceptedFiles2 = this.handleAcceptedFiles2.bind(this);
    this.handleAcceptedFiles3 = this.handleAcceptedFiles3.bind(this);
    this.handleAcceptedFiles3 = this.handleAcceptedFiles3.bind(this);

    this.state = {
      breadcrumbItems: [
        { title: "Property Registration", link: "#" },
        { title: "Image Upload", link: "#" },
      ],


      isShown:"none",
      selectedFiles: [],
      selectedFiles2: [],
      selectedFiles3: [],
      selectedFiles4: [],


      file:'',
      propid:"",
      img1:'',
      img2:'',
      img3:'',

    };
  }



  componentDidMount() {

    const id=window.location.pathname;

    const answer = id.split('/');

    const path = answer[2];


    this.setState({ propid: path })
    const uri=`https://adpavilion.herokuapp.com/auth-check`;



    const options = {
      method: 'GET',
      credentials: "include",
      // If you add this, upload won't work
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // }
    };


    fetch(uri, options)
    .then((response) => response.json())
    .then((data) => {

      if(data[0].estimd===0){
        this.setState({art:true})
    
    }
   

    })


  }

  handleAcceptedFiles = (e) => {
    e.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles: e });
    this.setState({ file: e[0] })


  };



  handleAcceptedFiles2 = (e) => {
    e.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles2: e });
    this.setState({ img1: e[0] })


  };


  handleAcceptedFiles3 = (e) => {
    e.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles3: e });
    this.setState({ img2: e[0] })


  };


  handleAcceptedFiles4 = (e) => {
    e.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles4: e });
    this.setState({ img3: e[0] })


  };





  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };




  addUserData = async(e)=>{
    e.preventDefault();

    const formData = new FormData();
    formData.append("photo",this.state.file);

    formData.append("prop",this.state.propid);
    console.log(formData);
    console.log(this.state.propid);
    console.log(this.state.file);



    const options = {
      method: 'POST',
      body: formData,
      // If you add this, upload won't work
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // }
    };


      fetch('https://adpavilion.herokuapp.com/upload-loc', options)
      .then((response) => response.json())
      .then((data) => {


        if(data.status == 201){
          document.getElementById("sucess-div").style.display = "inline-block";

        }else{
          document.getElementById("fail-div").style.display = "inline-block";
        }
    




      })
      .catch(error => console.log(error));
  
}




addUserData2 = async(e)=>{
  e.preventDefault();

  const formData2 = new FormData();
  formData2.append("photo",this.state.img1);

  formData2.append("prop",this.state.propid);




  const options = {
    method: 'POST',
    body: formData2,
    // If you add this, upload won't work
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // }
  };


    fetch('https://adpavilion.herokuapp.com/upload-img1', options)
    .then((response) => response.json())
    .then((data) => {


      if(data.status == 201){
        document.getElementById("sucess-div2").style.display = "inline-block";

      }else{
        document.getElementById("fail-div2").style.display = "inline-block";
      }
  




    })
    .catch(error => console.log(error));

}


addUserData3 = async(e)=>{
  e.preventDefault();

  const formData3 = new FormData();
  formData3.append("photo",this.state.img2);

  formData3.append("prop",this.state.propid);




  const options = {
    method: 'POST',
    body: formData3,
    // If you add this, upload won't work
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // }
  };


    fetch('https://adpavilion.herokuapp.com/upload-img2', options)
    .then((response) => response.json())
    .then((data) => {


      if(data.status == 201){
        document.getElementById("sucess-div3").style.display = "inline-block";

      }else{
        document.getElementById("fail-div3").style.display = "inline-block";
      }
  




    })
    .catch(error => console.log(error));

}


addUserData4 = async(e)=>{
  e.preventDefault();

  const formData4 = new FormData();
  formData4.append("photo",this.state.img3);

  formData4.append("prop",this.state.propid);




  const options = {
    method: 'POST',
    body: formData4,
    // If you add this, upload won't work
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // }
  };


    fetch('https://adpavilion.herokuapp.com/upload-img3', options)
    .then((response) => response.json())
    .then((data) => {


      if(data.status == 201){
        document.getElementById("sucess-div4").style.display = "inline-block";

      }else{
        document.getElementById("fail-div4").style.display = "inline-block";
      }
  




    })
    .catch(error => console.log(error));

}


  render() {
    if(this.state.art === true){
      return (<Redirect to="/login" />);
  }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>

            <Breadcrumbs title="Image Upload" breadcrumbItems={this.state.breadcrumbItems} />


            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Upload The Property Location Map</CardTitle>
                    <p className="card-title-desc">Only Images <code>jpg and png</code> file formats are allowed, ideal size for images are <code>600w X 400h less than 400kb</code>
                    </p>
                    <Form>



                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input type="file" name="photo" {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>




<div id="sucess-div" className="sucess-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> The Images Was Uploaded Succesfully</h4>
</div>


<div id="fail-div" className="fail-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> There was an error uploading , Please Reload the Page</h4>
</div>


                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted fw-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                      <Button color="primary" type="button" onClick={this.addUserData} className="waves-effect waves-light">Upload Image</Button>
                    </div>
                    </Form>

               
                  </CardBody>
                </Card>
              </Col>



              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4"> CGI Image</CardTitle>
                    <p className="card-title-desc">Only Images <code>jpg and png</code> file formats are allowed, ideal size for images are <code>600w X 400h less than 400kb</code>
                    </p>
                    <Form>



                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles2(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input type="file" name="photo2" {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>




<div id="sucess-div2" className="sucess-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> The Images Was Uploaded Succesfully</h4>
</div>


<div id="fail-div2" className="fail-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> There was an error uploading , Please Reload the Page</h4>
</div>


                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles2.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted fw-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                      <Button color="primary" type="button" onClick={this.addUserData2} className="waves-effect waves-light">Upload Image</Button>
                    </div>
                    </Form>

               
                  </CardBody>
                </Card>
              </Col>


              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4"> Property Live Image 1</CardTitle>
                    <p className="card-title-desc">Only Images of <code>jpg and png</code> file formats are allowed, ideal size for images are <code>600w X 400h less than 400kb</code>
                    </p>
                    <Form>



                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles3(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input type="file" name="photo3" {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>




<div id="sucess-div3" className="sucess-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> The Images Was Uploaded Succesfully</h4>
</div>


<div id="fail-div3" className="fail-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> There was an error uploading , Please Reload the Page</h4>
</div>


                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles3.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted fw-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                      <Button color="primary" type="button" onClick={this.addUserData3} className="waves-effect waves-light">Upload Image</Button>
                    </div>
                    </Form>

               
                  </CardBody>
                </Card>
              </Col>

              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4"> Property Live Image 2</CardTitle>
                    <p className="card-title-desc">Only Images of <code>jpg and png</code> file formats are allowed, ideal size for images are <code>600w X 400h less than 400kb</code>
                    </p>
                    <Form>



                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles4(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input type="file" name="photo4" {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>




<div id="sucess-div4" className="sucess-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> The Images Was Uploaded Succesfully</h4>
</div>


<div id="fail-div4" className="fail-div" style={{display: this.state.isShown}}>

<h4 className="card-title-desc"> There was an error uploading , Please Reload the Page</h4>
</div>


                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles4.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted fw-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                      <Button color="primary" type="button" onClick={this.addUserData4} className="waves-effect waves-light">Upload Image</Button>
                    </div>
                    </Form>

               
                  </CardBody>
                </Card>
              </Col>
              

              <Col xs={6}>
                <Card>
                  <CardBody>
                  <div className="text-center mt-4">
                  <Link to={"/props"}>  <Button color="danger" type="button"  className="waves-effect waves-light fine-back-btn">Go Back</Button></Link>
                    </div>
                  </CardBody>
                </Card>
                  </Col>
                  <Col xs={6}>
                <Card>
                  <CardBody>
                  <div className="text-center mt-4">
                     <Link to={"/props"}> <Button color="success" type="button"  className="waves-effect waves-light fine-btn ">Finalize Image Update</Button></Link>
                    </div>
                  </CardBody>
                </Card>
                  </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormUpload;
