import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,InputGroup,  Button} from "reactstrap";
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Update Property Data", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            items:{},

            postfix1:0,
			postfix2: 10,
			postfix3: 0,
            postfix4: 0,
			postfix5: 0,
      postfix6: 8,
			postfix7: 10,
        };
    }



    componentDidMount(){

      const id=window.location.pathname;

       const answer = id.split('/');

       const path = answer[2];
        const uri=`https://adpavilion.herokuapp.com/props-ulist/${path}`;



        const options = {
          method: 'GET',
          credentials: "include",
          // If you add this, upload won't work
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // }
        };


        fetch(uri, options)
        .then((response) => response.json())
        .then((data) => {
    
    


          if(data.estimd===0){
            this.setState({art:true})
        
        }
       
          else{

            this.setState({items:data})
            this.setState({postfix1:data.struct})
            this.setState({postfix2:data.exter})
            this.setState({postfix3:data.inter})
            this.setState({postfix4:data.finishing})
            this.setState({postfix5:data.garden})
            this.setState({postfix6:data.bedroom})
            this.setState({postfix7:data.bathroom})
          }
      
    
    
        })

    }






    render() {
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Master Property Index" breadcrumbItems={this.state.breadcrumbItems} />
<form action="https://adpavilion.herokuapp.com/update-master" method="post">



<Row>
							<Col lg="6">
								<Card>
									<CardBody>

										<h4 className="card-title">Property Owner Credentials</h4>
										<p className="card-title-desc">You can't modify Property Owner's credentials here</p>
										<Label className="form-label">Property ID</Label>
								

                    <Input
											type="text"
								
defaultValue={this.state.items.prop_id}
											name="pr_id"
											id="defaultconfig"
                      readOnly
										/>
				
							
				
        <div className="mt-3">

        <Label className="form-label">Date of Registry</Label>
								

                <Input
                  type="text"
defaultValue={this.state.items.time}
                  name="timesup"
                  id="defaultconfig"
                  readOnly
                />
    
                    </div>




        <div className="mt-3">

        <Label className="form-label">Last Update</Label>
								

                <Input
                  type="text"
defaultValue={this.state.items.lastup}
                  name="lastup"
                  id="defaultconfig"
                  readOnly
                />
    
                    </div>


									
									</CardBody>
								</Card>
							</Col>


                            <Col lg="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Construction Progress</h4>
                    <p className="card-title-desc">
                There are 5 parameters taken at consideration, all values are in %
                    </p>
                     
                      <FormGroup className="mb-3">
                        <Label>Structure</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix1: this.state.postfix1 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix1}
                            placeholder="number"
                            name="struct"
                            
                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix1: this.state.postfix1 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>
                     
                      <FormGroup className="mb-3">
                        <Label>Exterior</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix2: this.state.postfix2 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix2}
                            placeholder="number"
                            name="exter"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix2: this.state.postfix2 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label>Interior</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix3: this.state.postfix3 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix3}
                            placeholder="number"
                            name="inter"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix3: this.state.postfix3 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label>Finishing</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix4: this.state.postfix4 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix4}
                            placeholder="number"
                            name="finishing"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix4: this.state.postfix4 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label>Gardens</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix5: this.state.postfix5 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix5}
                            placeholder="number"
                            name="garden"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix5: this.state.postfix5 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>
                    
                   
                  </CardBody>
                </Card>
              </Col>
                            <Col lg="6">
								<Card>
									<CardBody>

										<h4 className="card-title">Property Information</h4>
										<p className="card-title-desc">Input all the property information in the corresponding fields </p>
										<Label className="form-label">Property Type:</Label>
								

										<div className="mt-3">
                                                <select className="form-select" name="proptype">
                                                    <option  value={this.state.items.proptype}>{this.state.items.proptype}</option>
                                                    <option value="400m Villa">400m Villa</option>
                                                    <option value="500m Villa">500m Villa</option>
                                                    <option value="700m Villa - 2 Storey">700m Villa - 2 Storey</option>
                                                    <option value="700m Villa - 3 Storey">700m Villa - 3 Storey</option>
                                                    <option value="1000m Villa - 2 Storey">1000m Villa - 2 Storey</option>
                                                    <option value="1000m Villa - 3 Storey">1000m Villa - 3 Storey</option>

                                                </select>
                       
											
										</div>

                    <div className="mt-3">


                        <Label>Contract Date:</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="yyyy-mm-dd"
                            name="regdate"
                            defaultValue={this.state.items.purchase}
                         
                          />
                        </InputGroup>

                        </div>


                                        <div className="mt-3">
											<Label className="form-label">Contract Number:</Label>
										
											<Input
												type="text"
												name="contnumb"
												id="thresholdconfig"
                        defaultValue={this.state.items.contnumb}

											/>
											
										</div>


                    <div className="mt-3">
											<Label className="form-label">Phone Number:</Label>
                       
										
											<Input
												type="text"
                        placeholder="Start with country code e.g +964750xxxx"
												name="phone"
												id="thresholdconfig"
                                                    defaultValue={this.state.items.phone}

											/>
											
										</div>

                    <div className="mt-3">
											<Label className="form-label">Total Area: <code>in Square Meters</code></Label>
										
											<Input
												type="number"
												name="tarea"
												id="thresholdconfig"
                        defaultValue={this.state.items.totalarea}

											/>
											
										</div>



                    <div className="mt-3">
											<Label className="form-label">Total Plot Area: <code>in Square Meters</code></Label>
										
											<Input
												type="number"
												name="plot"
												id="thresholdconfig"
                        defaultValue={this.state.items.plotarea}

											/>
											
										</div>

                


    
                    <div className="mt-3">
											<Label className="form-label">Front View:</Label>
										
											<Input
												type="text"
												name="view"
												id="thresholdconfig"
                        defaultValue={this.state.items.view}

											/>
											
										</div>


									
									</CardBody>
								</Card>
							</Col>


              <Col lg="6">
								<Card>
									<CardBody>

										<h4 className="card-title">Property Specifications</h4>
										<p className="card-title-desc">Please type the corresponding property type's main 4 specifications </p>
										<Label className="form-label">First specification:</Label>
								

						
										
											<Input
												type="text"
												name="spec1"
												id="thresholdconfig"
                        defaultValue={this.state.items.spec1}

											/>
											



                      <div className="mt-3">
											<Label className="form-label">Second specification:</Label>
										
											<Input
												type="text"
												name="spec2"
												id="thresholdconfig"
                        defaultValue={this.state.items.spec2}

											/>
											
										</div>

                    
                    <div className="mt-3">
											<Label className="form-label">Third specification:</Label>
										
											<Input
												type="text"
												name="spec3"
												id="thresholdconfig"
                        defaultValue={this.state.items.spec3}

											/>
											
										</div>

                    
                    <div className="mt-3">
											<Label className="form-label">Fourth specification:</Label>
										
											<Input
												type="text"
												name="spec4"
												id="thresholdconfig"
                        defaultValue={this.state.items.spec4}

											/>
											
										</div>


                    <div className="mt-3">
											<Label className="form-label">Property Description:</Label>
										
											<Input
												type="textarea"
												name="desc"
                        maxLength={255}
                        placeholder=" Maximum 255 characters"
												id="thresholdconfig"
                        defaultValue={this.state.items.propdesc}

											/>
											
										</div>




                    <div className="mt-3">
                    <FormGroup className="mb-3">
                        <Label>Number of Bedrooms</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix6: this.state.postfix6 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix6}
                            placeholder="number"
                            name="bed"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">#</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix6: this.state.postfix6 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>
											
										</div>

                    
                    <div className="mt-3">
                    <FormGroup className="mb-3">
                        <Label>Number of Bathrooms</Label>
                        <InputGroup>
                          <span
                            className="input-group-btn input-group-prepend"
                            onClick={() =>
                              this.setState({ postfix7: this.state.postfix7 - 1 })
                            }
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-minus" />
                            </Button>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.postfix7}
                            placeholder="number"
                            name="bath"

                          />
                          <span className="input-group-append">
                            <span className="input-group-text">#</span>
                          </span>
                          <span className="input-group-append">
                            <Button
                              type="button"
                              onClick={() =>
                                this.setState({
                                    postfix7: this.state.postfix7 + 1
                                })
                              }
                              color="primary"
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </span>
                        </InputGroup>
                      </FormGroup>
											
											
										</div>


                




									
									</CardBody>
								</Card>
							</Col>



              <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Payment Information</h4>
<p></p>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Payment Plan:</Label>
                                            <Col md={10}>
                                            <select className="form-select" name="paystat">
                                            <option  value={this.state.items.paystat}>{this.state.items.paystat}</option>

                                                    <option  value="Installment">Installment</option>
                                                    <option value="Paid in Cash">Cash</option>
                                                </select>                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Next Amount Due:</Label>
                                            <Col md={10}>
                                                <Input type="number" defaultValue={this.state.items.inst_amount} name="instamount" minLength="8" placeholder="Next installment amount or $0 if the payment plan is cash" id="example-search-input" />
                                            </Col>
                                        </Row>


                                        <Row className="mb-3">

<Label className="col-md-2 col-form-label">Next Payment Date: </Label>
<Col md={10}>
<Flatpickr
className="form-control d-block"
placeholder="yyyy-mm-dd"
name="instnext"
defaultValue={this.state.items.inst_next}
/>

</Col>

</Row>
                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Last Payment Date: </Label>
                                            <Col md={10}>
                                            <Flatpickr
                            className="form-control d-block"
                            placeholder="yyyy-mm-dd"
                            name="instlast"
                            defaultValue={this.state.items.inst_last}
                          />
                                            
                                            </Col>


                                            

                                        </Row>

                                        <Col md={12}>
 
 <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block  btn-submit-block"
                       type="submit"
                     >
                       Update and Proceed 
                     </Button>
                   </div>
 </Col>	
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

	
			  </Row>

              </form>


              <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      


 <Link to="/props">
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block btn-back "
                       type="submit"
                     >
                       Go Back
                     </Button>
                   </div>
                   </Link>
                                    </CardBody>
                                </Card>
                            </Col>


                            
                        </Row>



                  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
