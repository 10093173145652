import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class EarningReports extends Component {

    constructor(props) {
        super(props);


   this.state = {
        menu: false,
        series: [23],
        options: {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#0ebfe8'],
            stroke: {
                lineCap: 'round'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '70%'
                    },
                    track: {
                        margin: 0,
                    },

                    dataLabels: {
                        show: false
                    }
                }
            }
        },
  
        datainst:{}
    }}




    
    componentDidMount() {


        var datid=window.location.pathname;

        var answers = datid.split('/');
 
        var prog = answers[2];
    
         var uri=`https://adpavilion.herokuapp.com/prog-stat/${prog}`;

       fetch(uri)
       .then((response) => response.json())
       .then(instdata => {

var pro=[instdata.leftover];



this.setState({datainst:instdata});
           this.setState({ series:pro } );

       },[this.state]);
   }
   


    render() {

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Dropdown className="float-end" isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })} >
                            <DropdownToggle tag="i" className="arrow-none card-drop" >
                                <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">

                              <div></div>
                            </DropdownMenu>
                        </Dropdown>

                        <h4 className="card-title mb-4">Your Property Payment Status</h4>
                        <div className="text-center">
                            <Row>
                                <Col sm={12}>
                                    <div>
                                        <div className="mb-3">
                                            <div id="radialchart-1" className="apex-charts">
                                                <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="60" />
                                            </div>
                                        </div>

                                        <p className="text-muted text-truncate mb-2">Status</p>
                                        <h5>${this.state.datainst.inst_amount} | {this.state.datainst.paystat}: {this.state.datainst.inst_next}</h5>
                                                                

                                    </div>
                                </Col>

                            </Row>

                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default EarningReports;