import React, { Component } from 'react';
import { Container, CardBody, Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'

import Breadcrumbs from '../../components/Common/Breadcrumb';



class Timeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Pavilion Erbil", link: "#" },
                { title: "Event Timeline", link: "#" },
            ],

            events: []

        }
    }



    componentDidMount() {
        const id=window.location.pathname;

        const answer = id.split('/');
 
        const path = answer[2];

         const uri=`https://adpavilion.herokuapp.com/event-list/${path}`;
   
       
       fetch(uri,{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(eventList => {


        if(eventList[0].estimd===0){
            this.setState({art:true})
        
        }else{
            this.setState({ events: eventList });
      
          }

      
       });
   }
   

    render() {
        if(this.state.art === true){
            return (<Redirect to="/login" />);
        }
        console.log(this.state.events)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Events Timeline" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row className="justify-content-center">
                        

                            
                        </Row>
                        <Row className="justify-content-center">


                            <Col xl={10}>
                                <div className="timeline" dir="ltr">
                                    <div className="timeline-item timeline-left">
                                        <div className="timeline-block">
                                            <div className="time-show-btn mt-0">
                                                <Link to="#" className="btn btn-danger btn-rounded w-lg">Your Pavilion</Link>
                                            </div>
                                        </div>
                                    </div>
                        

                            

                                    {

       
            this.state.events.map((item,i) => 

                      


                                    <div  key={i} className={item.position}>
                                        <div className="timeline-block">
                                            <Card className="timeline-box">
                                                <CardBody>
                                                    <span className="timeline-icon"></span>
                                                    <div className="timeline-date">
                                                        <i className="mdi mdi-circle-medium circle-dot"></i> {item.eventdate}
                                                    </div>
                                                    <h5 className="mt-3 foont-size-15"> {item.eventhead}</h5>
                                                    <div className="text-muted">
                                                        <p className="mb-0">{item.eventpar}</p>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>

                           

                                    )
         }
             
          

                                </div>
                                
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Timeline;