import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "./datatables.scss"

// Table data
import { useHistory } from 'react-router-dom';



function DatatableTables() {

  const history = useHistory();

  useEffect( () => {
      fetchproducts();
  }, []);

  const [products, setProducts] = useState([]);

  const fetchproducts = async () => {
      const data = await fetch('https://adpavilion.herokuapp.com/props-list',{      method: "GET",
      credentials: "include"})

        const products = await data.json();

     if(products[0].estimd===0){

      history.push('/login')
    }else{
      setProducts(products);    

    }

  };

 
const object={

page: 1,
sizePerPage: 10,
productData: products
}

   


const total=products.length;

console.log(total)

const columns = [{
  dataField: "id",
  text: 'Sequence',
  hidden: true,

}, {
  dataField: 'prop_id',
  text: 'Property ID',
  sort: true
}, {
  dataField: 'username',
  text: 'User Name',
  sort: true
}, {
  dataField: 'proptype',
  text: 'Property Type',
  sort: true
}, 

{
  dataField: 'bathroom',
  text: 'Update Details',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >
<a data-toggle="tooltip" title="Edit Property" className='edits-icon' href={"/reg-props/"+`${row.prop_id}` } ><i className="fas fa-edit"></i></a>
<a data-toggle="tooltip" title="Update Images" className="table-iconss" href={"/form-image-update/"+`${row.prop_id}`} ><i className="fas fa-image"></i></a>

<a data-toggle="tooltip" title="Show Property Events" className="table-iconss sp-icon" href={"/event-table/"+`${row.id}`} ><i className="fas fa-sitemap"></i></a>
<a  data-toggle="tooltip" title="Modify Credentials" className="table-iconss cred-icon" href={"/props-uc/"+`${row.id}`}><i className="fas fa-key"></i></a>

    </div>
); } ,

},

{
  dataField: 'bedroom',
  text: 'Impersonate ',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >
        <a data-toggle="tooltip" title="User Dashboard" className="table-iconss" href={"/dashboard/"+`${row.id}`} target="_blank"><i className="fas fa-tachometer-alt" ></i></a>

    <a data-toggle="tooltip" title="User Property Details" className="table-iconss" href={"/prop-detail/"+`${row.id}`} target="_blank"><i className="fas fa-home"></i></a>

    <a data-toggle="tooltip" title="User Property Events" className="table-iconss" href={"/events/"+`${row.id}`} target="_blank"><i className="fas fa-sitemap"></i></a>

    </div>
); } ,

}


];

const defaultSorted = [{
  dataField: 'id',
  order: 'desc'
}];

const pageOptions = {
  sizePerPage: 10,
  totalSize: products.length, // replace later with size(customers),
  custom: true,
}


const { SearchBar } = Search;
const  breadcrumbItems= [
  { title: "Pavilion Erbil", link: "#" },
  { title: "Notifications", link: "#" },
]

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h3">Property Owners List </CardTitle>
                    <p className="card-title-desc">
This is the list of registered properties and user credentials, Total registered property as of today is {total} properties.
                    </p>


                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={object.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={object.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap "
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-products-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  
}

export default DatatableTables