import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,  Button} from "reactstrap";
import { Redirect } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Register New Admin", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true
        };
    }


      
    componentDidMount(){


        const uri=`https://adpavilion.herokuapp.com/auth-check`;



        const options = {
          method: 'GET',
          credentials: "include",
          // If you add this, upload won't work
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // }
        };


        fetch(uri, options)
        .then((response) => response.json())
        .then((data) => {
    
          if(data[0].estimd===0){
            this.setState({art:true})
        
        }
       
    
        })

    }


    render() {
              
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Admin Registration Form" breadcrumbItems={this.state.breadcrumbItems} />
<form action="https://adpavilion.herokuapp.com/register-admin" method="post">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Please Fulfil The Following Fields</h4>
<p></p>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Admin Email Address</Label>
                                            <Col md={10}>
                                                <Input type="email" name="uname" placeholder="e.g sales@pavilionerbil.com" id="example-text-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Password</Label>
                                            <Col md={10}>
                                                <Input type="search" name="pw" minLength="8" placeholder="Must be 8 characters long" id="example-search-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Privilege </Label>
                                            <Col md={10}>
                                                <select className="form-select" name="prevs">
                                                    <option defaultValue value="0">Is Moderator </option>
                                                    <option value="1">Is Super Admin</option>
                                                </select>
                                                <div className="attention-note">
                                                <p>Moderators Can Add, View, Modify and Update Property Data, While Super Admin can Add, View, Modify, Update and delete Property data, also perform registration of new admins and/or modifing administrator roles and password changes. </p>

                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-3">

<Col md={12}>
 
<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm btn-block  btn-submit-block"
                      type="submit"
                    >
                      Submit The Registration of a New Admin
                    </Button>
                  </div>
</Col>

</Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </form>

                        <Row className="mb-3">

<Col md={12}>
<Card>
<CardBody>
<Link to="/admin-list">

<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm btn-block  btn-back"
                      type="submit"
                    >
                      Go Back to Admins List
                    </Button>
                  </div>
                  </Link>

</CardBody>
</Card>
</Col>

</Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
