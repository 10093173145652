import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class RevenueAnalytics extends Component {


    constructor(props) {
        super(props);
     

        this.state = {
            series: [{
                name: '2023',
                type: 'column',
                data: [0,0,0]
            }, ],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
              
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
    
                legend: {
                    show: false,
                },
                colors: ['#0ebfe8'],
                labels: ['Three months ago', 'Previoues Month', 'This Month'],
            },
            dataL: {},

        }
        
    }


    
    componentDidMount() {

   
         var uri=`https://adpavilion.herokuapp.com/dash-revs`;

       fetch(uri)
       .then((response) => response.json())
       .then(fdata => {

const construct=[fdata.thre,fdata.prev,fdata.curent];



this.setState({dataL:fdata});
           this.setState({ series: [{
            name: '2023',
            type: 'column',
            data: construct
        }, ] });

       },[this.state]);


   }
   

 


    render() {


        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active type="button"></Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Property Registration Process</h4>
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="280" />
               
                            </div>
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Three Months Ago:</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{this.state.dataL.thre}</h5>
                                        <div className="text-success">
                                            <i className="mdi mdi-menu-up font-size-14"> </i>
                                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Previoues Month:</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{this.state.dataL.prev}</h5>
                                        <div className="text-success">
                                            <i className="mdi mdi-menu-up font-size-14"> </i>
                                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> This Month:</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0">{this.state.dataL.curent}</h5>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RevenueAnalytics;