import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Modal,CardImg, ModalHeader,Form, ModalBody, ModalFooter,CardBody, Card, Input, Label, Container } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import EmailSidebar from "./email-sidebar";
import EmailToolBar from "./email-toolbar";

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/logo-dark.png";

class EmailRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Messages", link: "#" },
                { title: "Read Message", link: "#" },
            ],
            modal: false,

            emailbody: {},

        };
        this.togglemodal.bind(this);

    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

     
    componentDidMount() {
        const id=window.location.pathname;

        const answer = id.split('/');
 
        const epath = answer[2];
     

         const uri=`https://adpavilion.herokuapp.com/email-body-admin/${epath}`;
   
       fetch(uri,{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(emailList => {
           this.setState({ emailbody: emailList }
            
            
            
            
            
            );
         




console.log(emailList)
       });
   }
   

     

    render() {


        var elength = this.state.emailbody.length
        var first = this.state.emailbody[0];

const inboxprops={ids:first?.owners_id,lenghtemail:elength}



        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Read Message" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>

                                {/* left sidebar */}
                                <EmailSidebar props={inboxprops}/>

                                <div className="email-rightbar mb-3">

                                    <Card>
                                        {/* email toolbar */}
                                        <EmailToolBar />
                                    
              
                                        <CardBody>
                                  
                                            <div  className="d-flex mb-4">
                                                <img className="d-flex me-3 rounded-circle avatar-sm" src={avatar2} alt="Generic placeholder" />
                                                <div className="flex-1">
                                                    <h5 className="font-size-14 my-1">Owner: {this.state.emailbody.prop_id}</h5>
                                                    <small className="text-muted">{this.state.emailbody.username}</small>
                                                </div>
                                            </div>


                                            <div  className="d-flex mb-4">
                                                <div className="flex-1">
                                                <h4 className="mt-0 font-size-16">Subject: {this.state.emailbody.subject}</h4>
                                                    <small className="text-muted">on {this.state.emailbody.date} - {this.state.emailbody.time}</small>
                                                </div>
                                            </div>


                                            <p>{this.state.emailbody.ebody}</p>
                                            
                                            <p>Sincerly,</p>

                                            <p>{this.state.emailbody.sentby}</p>
                                            <hr />

                                            <Row>
                                                <Col xl={2} xs={6}>
                                                    <Card>
                                                        <CardImg top className="img-fluid" src={img3} alt="Card cap" />
                                                        <div className="py-2 text-center">
                                                        </div>
                                                    </Card>
                                                </Col>
                                          
                                            </Row>
                                            <Link to={`/email-inbox`} className="btn btn-secondary waves-effect mt-4"><i className="mdi mdi-reply"></i> Go Back</Link>
                                            <Button  onClick={this.togglemodal} className="btn btn-danger waves-effect mt-4 far-btn"><i className="mdi mdi-message-reply"></i> Reply</Button>

                                            <Modal isOpen={this.state.modal} role="document" autoFocus={true} centered={true} className="composemodal" tabIndex="-1" toggle={this.togglemodal}>

                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            New Message
                            </ModalHeader>
                        <ModalBody>
                            <Form action="https://adpavilion.herokuapp.com/send-eadmin-reply" method="post" >
                                <div className="mb-3">
<Label for="toto">To:</Label>
                                    <Input type="text"  className="form-control" name="display" placeholder={`Property Id: ${this.state.emailbody.prop_id} | Username: ${this.state.emailbody.username}`}  readOnly></Input>
                                    <Input type="text" defaultValue={this.state.emailbody.id} className="form-control hidden" name="toto" placeholder="To"></Input>
                                    <Input type="text" defaultValue={this.state.emailbody.email_id} className="form-control hidden" name="orgid" placeholder="To"></Input>

                                </div>
                                <Label for="from">Original Message Body:</Label>

<div className="mb-3">

    <Input type="text" className="form-control" name="omessage" placeholder={this.state.emailbody.ebody} readOnly>




   
</Input>

</div>
                                <Label for="from">From:</Label>

                                <div className="mb-3">

                                    <Input type="select" className="form-control" name="from" placeholder="from" readOnly>

                                    <option value={this.state.emailbody.to}>{this.state.emailbody.to}</option>
       

      
                                   
          </Input>

                                </div>

                                <Label for="from">Subject:</Label>

                                <div className="mb-3">
                                    <Input type="text" className="form-control"  value={this.state.emailbody.subject}  name="subject" readOnly/>
                                </div>
                                <div className="mb-3">
                                  <Input type="textarea" className="form-control" placeholder="Your Reply" name="mbodysent"></Input>
                                </div>
                                <ModalFooter>
                            <Button coloe="secondary" type="button" onClick={this.togglemodal} >Close</Button>
                            <Button type="submit" color="primary">Send <i className="fab fa-telegram-plane ms-1"></i></Button>
                        </ModalFooter>
                            </Form>
                        </ModalBody>
                   
                    </div>
                </Modal>
                                            
                                             </CardBody>
                                        
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailRead;