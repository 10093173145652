import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Card, Form, Label } from "reactstrap";
import { Link } from "react-router-dom";

//Import Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Import Images

class EmailSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            propdata:[]
        };
        this.togglemodal.bind(this);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }


 
    componentDidMount(){

       
          const fetchuri=`https://adpavilion.herokuapp.com/email-proplist`;

  
          const options = {
            method: 'GET',
            credentials: "include",
            // If you add this, upload won't work
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // }
          };
  
  
          fetch(fetchuri, options)
          .then((response) => response.json())
          .then((data) => {
      
      
            if(data.status = 200){
  
                console.log(data)

  this.setState({propdata:data})

            }else{
  
              console.log("error")
  
            }
        
      
      
      
      
          })
          .catch(error => console.log(error));
  
      }


    render() {

        return (
            <React.Fragment>
                {/* left sidebar start */}
                <Card className="email-leftbar">
                    <Button type="button" color="danger" onClick={this.togglemodal} block className="waves-effect waves-light" >
                        Compose
                                    </Button>
                    <div className="mail-list mt-4">
                        <Link to="/email-inbox" className="active"><i className="mdi mdi-email-outline me-2"></i> Inbox <span className="ms-1 float-end">Viewing {this.props.props.lenghtemail}</span></Link>
                        <Link to="/email-isent"><i className="mdi mdi-email-check-outline me-2"></i>Sent Mail</Link>
                    </div>


                
                </Card>
                {/* left sidebar over */}
                <Modal isOpen={this.state.modal} role="document" autoFocus={true} centered={true} className="composemodal" tabIndex="-1" toggle={this.togglemodal}>
                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            New Message
                            </ModalHeader>
                        <ModalBody>
                            <Form action="https://adpavilion.herokuapp.com/send-eadmin" method="post" >
                                <div className="mb-3">
<Label for="toto">To:</Label>
                                    <Input type="select" className="form-control" name="toto" placeholder="To" >
                                   { this.state.propdata.map((item,i) =>        
                                    <option key={i} value={item.id}>Property ID: {item.prop_id} | Username: {item.username}</option>

                                    )}
          </Input>

                                </div>

                                <Label for="from">From:</Label>

                                <div className="mb-3">

                                    <Input type="select" className="form-control" name="from" placeholder="from" >

                                    <option value="Pavilion Erbil Sales Office">Pavilion Erbil Sales Office</option>
                                    <option value="Pavilion Erbil Help Desk">Pavilion Erbil Help Desk</option>
                                    <option value="Pavilion Erbil Legal Department">Pavilion Erbil Legal Department</option>

      
                                   
          </Input>

                                </div>


                                <div className="mb-3">
                                    <Input type="text" className="form-control" placeholder="Subject" name="subject" />
                                </div>
                                <div className="mb-3">
                                  <Input type="textarea" className="form-control" placeholder="Your Message" name="mbodysent"></Input>
                                </div>
                                <ModalFooter>
                            <Button coloe="secondary" type="button" onClick={this.togglemodal} >Close</Button>
                            <Button type="submit" color="primary">Send <i className="fab fa-telegram-plane ms-1"></i></Button>
                        </ModalFooter>
                            </Form>
                        </ModalBody>
                   
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default EmailSidebar;