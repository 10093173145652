import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle,Button } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import "./datatables.scss"
import { useHistory } from 'react-router-dom';

// Table data



function DatatableTables() {
  const history = useHistory();

  useEffect( () => {
      fetchproducts();
  }, []);

  const [products, setProducts] = useState([]);



  const id=window.location.pathname;

  const answer = id.split('/');

  const path = answer[2];

   const uri=`https://adpavilion.herokuapp.com/event-list/${path}`;


  const fetchproducts = async () => {
      const data = await fetch(uri,{      method: "GET",
      credentials: "include"})
        const products = await data.json();
     
        console.log(products)

        if(products[0].estimd===0){

          history.push('/login')
        }
        
        
        else{
          setProducts(products);    

        }

  };

 
const object={

page: 1,
sizePerPage: 10,
productData: products
}

   





const columns = [{
  dataField: "eventid",
  text: 'Sequence',
  hidden: true,

}, {
  dataField: 'eventhead',
  text: 'Event Header',
  sort: true
}, {
  dataField: 'eventpar',
  text: 'Event Paragraph',
  sort: true
}, {
  dataField: 'eventdate',
  text: 'Date',
  sort: true
}, {
  dataField: 'position',
  text: 'Position',
  sort: true
}, {
  dataField: 'timestamp',
  text: 'Edit Notification',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >
<a className="table-iconss" data-toggle="tooltip" title="Edit Notification" href={"/event-update/"+`${row.eventid}`}><i className="fas fa-edit"></i></a>

    </div>
); } ,

},


, {
  dataField: 'icon',
  text: 'Delete Notification',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >


<form action={"https://adpavilion.herokuapp.com/delete-event/"+`${row.eventid}`} method="post">
<button className="not-btn red-table" data-toggle="tooltip" title="Delete" type="submit"><i className="fas fa-trash"></i></button>
</form>
    </div>
); } ,

},];

const defaultSorted = [{
  dataField: 'noteid',
  order: 'desc'
}


];

const pageOptions = {
  sizePerPage: 10,
  totalSize: products.length, // replace later with size(customers),
  custom: true,
}


const { SearchBar } = Search;
const  breadcrumbItems= [
  { title: "Super Admin", link: "#" },
  { title: "Property Events", link: "#" },
]

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Property Events </CardTitle>
                    
                    <p className="card-title-desc">
                     This is the Events related to this property
                    </p>

                
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='eventid'
                      columns={columns}
                      data={object.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='eventid'
                          columns={columns}
                          data={object.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap "
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-products-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>


              <Col className="col-12">
              <Link to={"/event-pub/"+`${path}`}>

<Card >
    <CardBody>
      

    <div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-md btn-block  btn-submit-block"
                      type="submit"
                    >
                      Create New Event For this Property
                    </Button>
                  </div>
    </CardBody>
</Card>
</Link>
              </Col>
          
            </Row>



            
            <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      


 <Link to="/props">
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block btn-back "
                       type="submit"
                     >
                       Go Back
                     </Button>
                   </div>
                   </Link>
                                    </CardBody>
                                </Card>
                            </Col>


                            
                        </Row>

          </div>
        </div>
      </React.Fragment>
    )
  
}

export default DatatableTables