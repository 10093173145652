import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,  Button} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Update Admin Roles", link: "#" },
            ],
            idpath:"",
            customchk: true,
            toggleSwitch: true,
            admindata:{},
       
        };
    }







    componentDidMount(){

        const id=window.location.pathname;
  
         const answer = id.split('/');
  
         const path = answer[2];
          const fetchuri=`https://adpavilion.herokuapp.com/admin-udata/${path}`;


  this.setState({idpath:path})
  
          const options = {
            method: 'GET',
            credentials: "include",
            // If you add this, upload won't work
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // }
          };
  
  
          fetch(fetchuri, options)
          .then((response) => response.json())
          .then((data) => {
      
      
            if(data.estimd===0){
                this.setState({art:true})
            
            }else{
                this.setState({admindata:data})
          
              }
    
          
        
      
      
      
      
          })
  
      }














    render() {
        if(this.state.art === true){
            return (<Redirect to="/login" />);
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Admin Update Form" breadcrumbItems={this.state.breadcrumbItems} />
<form action="https://adpavilion.herokuapp.com/admin-update" method="post">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Please Fulfil The Following Fields</h4>
<p></p>



<Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">System ID</Label>
                                            <Col md={10}>
                                                <Input type="number" name="idofadmin" defaultValue={this.state.idpath} placeholder="e.g sales@pavilionerbil.com" id="example-text-input" readOnly />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Admin Email Address</Label>
                                            <Col md={10}>
                                                <Input type="email" name="uname" defaultValue={this.state.admindata.username} placeholder="e.g sales@pavilionerbil.com" id="example-text-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Password</Label>
                                            <Col md={10}>
                                                <Input type="search" name="pw" minLength="8" placeholder="Must be 8 characters long, Leave Blank if you don't want to change the password" id="example-search-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Privilege </Label>
                                            <Col md={10}>
                                                <select className="form-select" name="prevs">

                                                    <option defaultValue={this.state.admindata.role}>{this.state.admindata.role} </option>
                                                    <option value="0">Is Moderator</option>
                                                    <option value="1">Is Super Admin</option>

                                                </select>
                                                <p><code>0= Moderator Role   |   1 = Super Admin</code></p>
                                                <div className="attention-note">
                                                <p>Moderators Can Add, View, Modify and Update Property Data, While Super Admin can Add, View, Modify, Update and delete Property data, also perform registration of new admins and/or modifing administrator roles and password changes. </p>

                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-3">

<Col md={12}>
 
<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm btn-block  btn-submit-block"
                      type="submit"
                    >
                      Update The Admin Credantials
                    </Button>
                  </div>
</Col>

</Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </form>

                  

                        <Col className="col-12">
              <Link to="/admin-list">

<Card >
    <CardBody>
      

    <div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm  btn-block  btn-back"
                      type="button"
                    >
                      Go Back to Admins List
                    </Button>
                  </div>
    </CardBody>
</Card>
</Link>
              </Col>
          




                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
