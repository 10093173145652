import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,  Button} from "reactstrap";
import { Redirect } from 'react-router'

import Breadcrumbs from '../../components/Common/Breadcrumb';

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Update Push Notification", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            nots:{},
            idnote:""
        };


    }



    
    componentDidMount(){

        const id=window.location.pathname;
  
         const answer = id.split('/');
  
         const path = answer[2];
          const uri=`https://adpavilion.herokuapp.com/not-single/${path}`;
  
  this.setState({idnote:path})
  
          const options = {
            method: 'GET',
            credentials: "include",

          };
  
  
          fetch(uri, options)
          .then((response) => response.json())
          .then((data) => {
      
      
            if(data.estimd===0){
                this.setState({art:true})
            }else{
                this.setState({nots:data})
          
              }
    
          
 
      
          })
  
      }

    render() {
                      
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }

    console.log(this.state.nots)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Update Notification " breadcrumbItems={this.state.breadcrumbItems} />
<form action={`https://adpavilion.herokuapp.com/update-note/${this.state.idnote}`} method="post">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Please Fulfil The Following Fields</h4>
<p></p>


                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Notification Header</Label>
                                            <Col md={10}>
                                                <Input type="text" name="notehead" defaultValue={this.state.nots.noth} maxLength="50" placeholder="Maximum Characters allowed is 50, Including Spaces" id="example-text-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Notification Body</Label>
                                            <Col md={10}>
                                                <Input type="text" name="notbodey" maxLength="200"  defaultValue={this.state.nots.notb} placeholder="Maximum Characters allowed is 200, Including Spaces  " id="example-search-input" />
                                            </Col>
                                        </Row>


                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Notification Icons</Label>
                                            <Col md={10}>
                                            <div>

                                            <Label className="icon-sets"><i className="ri-notification-2-line"></i> Bell Notification</Label>
                                            <Label  className="icon-sets"><i className="ri-home-3-line"> </i>House Icon</Label>
                                            <Label  className="icon-sets"><i className="ri-account-circle-line"> </i>User Icon</Label>
                                            <Label  className="icon-sets"><i className="ri-bookmark-3-fill"> </i>Start Badge Icon</Label>

             
                                            </div>

                                            </Col>
                                        </Row>


                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Notification Icon </Label>
                                            <Col md={10}>
                                     
                                                <select className="form-select" name="iconnots">
                                                <option defaultValue={this.state.nots.icon} >Bell Icon - General Notifications</option>

                                                    <option  value="ri-notification-2-line">Bell Icon - General Notifications</option>
                                                    <option value="ri-home-3-line">Home Icon - For Construction and Milestone Notification</option>
                                                    <option value="ri-account-circle-line">Users Icon - User and system features notification</option>
                                                    <option value="ri-bookmark-3-fill">Star Badge - Special Notifications</option>

                                                </select>
                                                <div className="attention-note">
                                                <p>Push Notification are global and All users will be able to view them, Make sure you spell check everything before pushing the notification</p>
                                                <p>Date and time of the push notification is registered automatically and is set to the time of publishing</p>

                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-3">

<Col md={12}>
 
<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm btn-block  btn-submit-block"
                      type="submit"
                    >
                      Update This Notification
                    </Button>
                  </div>
</Col>

</Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </form>

                  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
