import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle,Button } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

//Import Breadcrumb
import "./datatables.scss"

// Table data



function DatatableTables() {
  const history = useHistory();

  useEffect( () => {
      fetchproducts();
  }, []);

  const [products, setProducts] = useState([]);

  const fetchproducts = async () => {
      const data = await fetch('https://adpavilion.herokuapp.com/notifies',{      method: "GET",
      credentials: "include"})

        const products = await data.json();
     

  
        if(products[0].estimd===0){
          console.log("what")
    
          history.push('/login')
        }else{
          setProducts(products);    ;

        }

  };

 
const object={

page: 1,
sizePerPage: 10,
productData: products
}

   






const columns = [{
  dataField: "noteid",
  text: 'Sequence',
  hidden: true,

}, {
  dataField: 'notehead',
  text: 'Notification Title',
  sort: true
}, {
  dataField: 'notebody',
  text: 'Notification Detail',
  sort: true
}, {
  dataField: 'date',
  text: 'Date',
  sort: true
}, {
  dataField: 'time',
  text: 'Time',
  sort: true
}, {
  dataField: 'timestamp',
  text: 'Edit Notification',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >
<a className="table-iconss" data-toggle="tooltip" title="Edit Notification" href={"/not-update/"+`${row.noteid}`} target="_blank"><i className="fas fa-edit"></i></a>

    </div>
); } ,

},


, {
  dataField: 'icon',
  text: 'Delete Notification',
  isDummyField: true,

  formatter: (cell, row, rowIndex, formatExtraData)=> { 
    return ( 
      <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        lineHeight: "normal"
      }}
    >


<form action={"https://adpavilion.herokuapp.com/delete-note/"+`${row.noteid}`} method="post">
<button className="not-btn red-table" data-toggle="tooltip" title="Delete" type="submit"><i className="fas fa-trash"></i></button>
</form>
    </div>
); } ,

},];

const defaultSorted = [{
  dataField: 'noteid',
  order: 'desc'
}


];

const pageOptions = {
  sizePerPage: 10,
  totalSize: products.length, // replace later with size(customers),
  custom: true,
}


const { SearchBar } = Search;
const  breadcrumbItems= [
  { title: "Pavilion Erbil", link: "#" },
  { title: "Notifications", link: "#" },
]

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Notifications </CardTitle>
                    
                    <p className="card-title-desc">
                     These is the Global Notifiactions from pavilion Erbil Administration and Sales office for all property owners
                    </p>

                
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='noteid'
                      columns={columns}
                      data={object.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='noteid'
                          columns={columns}
                          data={object.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap "
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-products-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>


              <Col className="col-12">
              <Link to="/not-pub">

<Card >
    <CardBody>
      

    <div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-md  btn-block  btn-submit-block"
                      type="submit"
                    >
                      Create New Push Notification
                    </Button>
                  </div>
    </CardBody>
</Card>
</Link>
              </Col>
          
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  
}

export default DatatableTables