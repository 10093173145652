import React, { Component } from 'react';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,

    Row,

  } from "reactstrap";
import { Link } from 'react-router-dom';

import { Redirect } from 'react-router'







class StarterPage extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Pavilion Erbil", link : "/" },
                { title : "News", link : "#" },
            ],

            newsl: [],

        }
    }


    componentDidMount() {

   
  
         const uri=`https://adpavilion.herokuapp.com/news-list`;
    
        fetch(uri,{      method: "GET",
        credentials: "include",
        
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
    
          "Access-Control-Allow-Credentials": true,
    
        },})
        .then((response) => response.json())
        .then(newsList => {

            if(newsList[0].estimd===0){
              this.setState({art:true})
          
          }else{
            this.setState({ newsl: newsList });

          }
        });
    }
    
      

    render() {
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Pavilion Erbil News" breadcrumbItems={this.state.breadcrumbItems} />
                    
  <React.Fragment>


 
  <Row>
                            <Col lg="6">
                                <Card>
                                    <CardBody>
                                      


 
                    <div className="d-grid">
                <p>All Users will view the news posts you publish, <code>Make Sure to spell check the post before publishing</code></p>
                   </div>
                                    </CardBody>
                                </Card>
                            </Col>




                            <Col className="col-6">
              <Link to="/form-news">

<Card >
    <CardBody>
      

    <div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-md  btn-block  btn-submit-block"
                      type="submit"
                    >
                      Publish a News
                    </Button>
                  </div>
    </CardBody>
</Card>
</Link>
              </Col>


                           
                        </Row>
                <Row>
                  <Col lg={12}>
                  <Card >
                      <CardBody>
                        <Row>        
          {
            this.state.newsl.map((item,i) =>            

         

            <Col  key={i} xl="4">
                          <div className='news-header'>
                                <h4 className=" card-title mb-4">{item.newshead}</h4>
                                <p>{item.newsdate}</p>

                                <hr className="my-4 line-news" />

                                </div>



                                <div >

                                    <p>{item.slicepar} ...</p>
                                </div>

                                        <div >

                                            <img alt="" className='news-img' src={`https://adpavilion.herokuapp.com/images/${item.newsimg}` } />
                                        
                                            </div>
                                            
                                            <div className='read-mores'>

                                            <div className="button-items">
                                            <Link to={{pathname:`/news-detail/${item.idnews}`,state:`${item.idnews}`}}>
                      <Button color="primary" type="button" className="waves-effect waves-light me-1">
                        Read Details <i className="ri-arrow-right-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      <Link to={`/form-news-update/${item.idnews}`}><Button color="warning" type="button" className="waves-effect waves-light me-1">
                        <i className="ri-error-warning-line align-middle me-2"></i> Update News
                                            </Button>
                                            </Link>

                    </div>


                                            
                                        </div>
                                        <div className='news-header-divider'>

                                        <hr className="my-4 line-news" />
                                        </div>


                                        </Col>



           )
         }
                        

            

         </Row>

</CardBody>
</Card>


</Col>
</Row>
                          </React.Fragment>

                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default StarterPage;