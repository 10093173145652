import React, { Component } from 'react';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,

    Row,

  } from "reactstrap";
import { Link } from 'react-router-dom';

import { Redirect } from 'react-router'







class StarterPage extends Component {

    
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Pavilion Erbil", link : "/" },
                { title : "News", link : "#" },
            ],

            newsdeep: [],
            neid:{},
            deletid:""
        }
    }


    componentDidMount() {

       
 
        const newspath = this.props.location.state;
      console.log(newspath)
         const uri=`https://adpavilion.herokuapp.com/news-list/${newspath}`;
    
        fetch(uri,{      method: "GET",
        credentials: "include",
        
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
    
          "Access-Control-Allow-Credentials": true,
    
        },})
        .then((response) => response.json())
        .then(newsdeepist => {

          if(newsdeepist[0].estimd===0){
            this.setState({art:true})
        
        }else{

            this.setState({ newsdeep: newsdeepist });
            this.setState({ deletid: newspath });
        }
        });
    }
    
      

    render() {
   if(this.state.art === true){
        return (<Redirect to="/login" />);
    }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Pavilion Erbil News" breadcrumbItems={this.state.breadcrumbItems} />
                    
  <React.Fragment>
                <Row>
                  <Col lg={12}>
                  <Card >
                      <CardBody>
                        <Row>        
          {
            this.state.newsdeep.map((item,i) =>            

           

            <Col  key={i} xl="12">
                          <div className='news-header'>
                                <h4 className=" card-title mb-4">{item.newshead}</h4>


                                </div>
                                
                                <div className='news-header-divider'>
                                <hr className="my-4 line-news" />

                                </div>

          

                                        <div className='mt-2 text-center news-detail-img'>

                                            <img alt=""  src={`https://adpavilion.herokuapp.com/images/${item.newsimg}` } />
                                        
                                            </div>


                                            <div className='news-tag-divider'>

                                     <h6><i className='ri-calendar-2-line'></i> {item.newsdate} <i className='ri-price-tag-3-line'></i> {item.newstag1} <i className='ri-price-tag-3-line'></i> {item.newstag2} <i className='ri-price-tag-3-line'></i> {item.newstag3}</h6>       
                                <hr className="my-4 line-news" />

                                </div>
                                
                                <div >

<p>{item.newspar1} 
</p>
</div>


<div >

<p>{item.newspar2} 
</p>
</div>


<div >

<p>{item.newspar3} 
</p>
</div>



<Col xl={12}>
                <Card>
                  <CardBody>
               
                    <div className="button-items">
                     <Link to="/news"> <Button color="primary" type="button" className="waves-effect waves-light me-1">
                        Go Back <i className="ri-arrow-right-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      <div>
                      <Link to={`/form-news-update/${this.state.deletid}`}><Button color="warning" type="button" className="waves-effect waves-light me-1">
                        <i className="ri-error-warning-line align-middle me-2"></i> Edit News
                                            </Button>
</Link>

</div>                         <form action={`https://adpavilion.herokuapp.com/delete-news/${this.state.deletid}`} method="post">           
                      <Button color="danger" type="submit" className="waves-effect waves-light me-1">
                        <i className="ri-close-line align-middle me-2"></i> Delete
                                            </Button>
                                            </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>


                                    

                                        </Col>



           )
         }
                        

            

         </Row>

</CardBody>
</Card>


</Col>
</Row>
                          </React.Fragment>

                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default StarterPage;