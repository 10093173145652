import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Modal,CardImg, ModalHeader,Form, ModalBody, ModalFooter,CardBody, Card, Input, Label, Container } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import EmailSidebars from "./email-sidebar-sent";
import EmailToolBar from "./email-toolbar";

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/logo-dark.png";

class EmailRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Messages", link: "#" },
                { title: "Read Message", link: "#" },
            ],

            emailbody: {},

        };

    }


     
    componentDidMount() {
        const id=window.location.pathname;

        const answer = id.split('/');
 
        const epath = answer[2];
     

         const uri=`https://adpavilion.herokuapp.com/email-body-admin/${epath}`;
   
       fetch(uri,{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(emailList => {
           this.setState({ emailbody: emailList }
            
            
            
            
            
            );
         




       });
   }
   

     

    render() {


        var elength = this.state.emailbody.length
        var first = this.state.emailbody[0];

const inboxprops={ids:first?.owners_id,lenghtemail:elength}



        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Read Message" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>

                                {/* left sidebar */}
                                <EmailSidebars props={inboxprops}/>

                                <div className="email-rightbar mb-3">

                                    <Card>
                                        {/* email toolbar */}
                                        <EmailToolBar />
                                    
              
                                        <CardBody>
                                  
                                            <div  className="d-flex mb-4">
                                                <img className="d-flex me-3 rounded-circle avatar-sm" src={avatar2} alt="Generic placeholder" />
                                                <div className="flex-1">
                                                    <h5 className="font-size-14 my-1">Sent To: Property Owner: {this.state.emailbody.prop_id}</h5>
                                                    <small className="text-muted">User: {this.state.emailbody.username}</small>
                                                </div>
                                            </div>


                                            <div  className="d-flex mb-4">
                                                <div className="flex-1">
                                                <h4 className="mt-0 font-size-16">By: {this.state.emailbody.sentby}</h4>

                                                <h4 className="mt-0 font-size-16">Subject: {this.state.emailbody.subject}</h4>
                                                    <small className="text-muted">on {this.state.emailbody.date} - {this.state.emailbody.time}</small>
                                                </div>
                                            </div>


                                            <p>{this.state.emailbody.ebody}</p>
                                            
                                            <p>Sincerly,</p>

                                            <p>{this.state.emailbody.sentby}</p>
                                            <hr />

                                            <Row>
                                                <Col xl={2} xs={6}>
                                                    <Card>
                                                        <CardImg top className="img-fluid" src={img3} alt="Card cap" />
                                                        <div className="py-2 text-center">
                                                        </div>
                                                    </Card>
                                                </Col>
                                          
                                            </Row>
                                            <Link to={`/email-isent`} className="btn btn-secondary waves-effect mt-4"><i className="mdi mdi-reply"></i> Go Back</Link>

                                             </CardBody>
                                        
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailRead;