import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
import errorImg from "../../assets/images/logo-dark.png";

class Error404 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="my-5 pt-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center my-5">
                                    <h1 className="fw-bold  error-text">Something Went Wrong! </h1>
                                    <h3 className="text-uppercase">Make sure to login again <code>If this Error persists please contact the system administrators</code></h3>
                                    <div className="mt-5 text-center">
                                        <Link to="/" className="btn btn-primary waves-effect waves-light" >Back to Login</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Error404;