import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Card, Input, Label, Container } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Redirect } from 'react-router'

//Import Components
import EmailSidebars from "./email-sidebar-sent";
import EmailToolBar from "./email-toolbar";

class EmailInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {

            breadcrumbItems: [
                { title: "Messages", link: "#" },
                { title: "Sent", link: "#" },
            ],

            emailss: [],

        }

    }



    
    componentDidMount() {

     

         const uri=`https://adpavilion.herokuapp.com/email-wesent`;
   
       fetch(uri,{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(emailList => {
           this.setState({ emailss: emailList });

           if(emailList[0].estimd===0){
            this.setState({art:true})
        
        }
       });
   }
   

    render() {

        if(this.state.art === true){
            return (<Redirect to="/login" />);
        }
        var elength = this.state.emailss.length
        var first = this.state.emailss[0];


const inboxprops={ids:first?.owners_id,lenghtemail:elength}

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Pavilion Erbil Express Contact" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                {/*Left sidebar  */}
                                <EmailSidebars props={inboxprops}/>


                                {/*  Right Sidebar */}
                                <div className="email-rightbar mb-3">

                                    <Card>

                                        {/* toolbar */}
                                        <EmailToolBar />

                                        <ul className="message-list">
                                        {
            this.state.emailss.map((item,i) =>        
                                            <li ke={i}>
                                                <div className="col-mail col-mail-1">
                                                    <div className="checkbox-wrapper-mail">
                                                        <Input type="checkbox" id="chk19" />
                                                        <Label htmlFor="chk19" className="toggle"></Label>
                                                    </div>
                                                    <Link to={`/email-isent-read/${item.email_id}`} className="title">Sent to: {item.prop_id} | {item.username}</Link>
                                                </div>
                                                <div className="col-mail col-mail-2">
                                                    <Link to={`/email-isent-read/${item.email_id}`} className="subject">{item.subject} – <span className={`teaser ${item.status}`}>{item.status}</span>
                                                    </Link>
                                                    <div  className="date">{item.date}</div>
                                                </div>

                                            </li>

                                         
                                       ) }

                                        </ul>

                                    </Card>

                                    <Row>
                                        <Col xs={7}>
                                            Showing {elength} Sent Replies
                                        </Col>
                                        <Col xs={5}>
                                            <ButtonGroup className="float-end">
                                                <Button type="button" size="sm" color="success" className="waves-effect"><i className="fa fa-chevron-left"></i></Button>
                                                <Button type="button" size="sm" color="success" className="waves-effect"><i className="fa fa-chevron-right"></i></Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>

                                </div>

                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailInbox;