import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Card, CardBody, Row, Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
function coure(props){


    return (

        <Card>
        <CardBody>

        <Row>
                                <Col sm={12}>
                                <div>
                                <h4 className="card-title mb-4">Master Map View</h4>

                                </div>
    <Carousel howArrows={true} emulateTouch={true}  autoPlay={true} showThumbs={false}	 dynamicHeight={false}>
        <div>

            <img alt="" src={'https://adpavilion.herokuapp.com/images/'+props.props.locs } />
            <p className="legend key-legend">Your Property Location</p>
        </div>
  
     
    </Carousel>
    </Col>

</Row>

    </CardBody>
                </Card>
    )
}


export default coure;