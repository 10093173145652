import React, {Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import RecentlyActivity from "./RecentlyActivity";


import Weather from './weather';


class ADDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Super Admin", link : "/" },
                { title : "Dashboard", link : "#" },
            ],
          
reps:[],
            statdata : {},
        }


    }



    


    componentDidMount(){

        
          const fetchuri=`https://adpavilion.herokuapp.com/dash-itemss`;


          const options = {
            method: 'GET',
            credentials: "include",

          };
  
        

          fetch(fetchuri, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: "include",
          })
          .then((response) => response.json())
          .then((data) => {
      
      
            if(data.alls = 1){
  
                console.log(data)
                console.log(data.alls)


                const repos =[
                    { icon : "ri-home-7-line", title : "Total Registered Properties", value : data.totals, rate : data.monthupdates, desc : "Updated This Month" },
                    { icon : "ri-home-gear-line", title : "Total Messages Received", value : data.totmesage, rate : data.replylengh, desc : "Has Been Replied" },
                    { icon : "ri-briefcase-4-line", title : "Total Events", value : data.totevent, rate : data.average, desc : "Average Per Property" },
                ];
  this.setState({reps:repos})


            }if(data.estimd === 0){
  
              console.log("error")
              this.setState({navs:true})

  
            }
        
      
      
      
      
          })
      }

 


    render() {

        if(this.state.navs === true){
            return (<Redirect to="/login" />);
        }

 
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={this.state.reps}/>
                                </Row>
                                
                                {/* revenue Analytics */}
                                <RevenueAnalytics />
                            </Col>

                            <Col xl={4}>

                                {/* sales Analytics */}
                                <SalesAnalytics />
                                {/* earning reports */}


                            </Col>

                            <Col xl={6}>

                            </Col>

                            
                            <Col xl={6}>

                            </Col>

                        </Row>
                        

                        <Row>
                            {/* sources */}

                            <RecentlyActivity />

{/* revenue by locations */}
<Weather/>


                        </Row>

                 

                    </Container> 
                </div>
            </React.Fragment>
        );
    }}

export default ADDashboard;
