import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Logins from "../pages/Authentication/Login copy";


import Loginfaild from "../pages/Authentication/Loginfail";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import DashboardSad from "../pages/Dashboard/indexSad";

import Propdetail from "../pages/Ecommerce/EcommerceProducts/PropDetail"

//Email
import EmailIsent from "../pages/Email/email-isent";
import EmailInbox from "../pages/Email/email-inbox";

import EmailRead from "../pages/Email/email-read";
import EmailReadSent from "../pages/Email/email-read-isent";


//Utility
import NewsLoad from "../pages/Utility/StarterPage";

import NewsDetail from "../pages/Utility/NewsDetail";

import Maintenance from "../pages/Utility/Maintenance";
import Timeline from "../pages/Utility/Timeline";

import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

import UserExist from "../pages/Utility/UserExist";
import Geror from "../pages/Utility/Geror";




//Tables
import Notstable from "../pages/Tables/NotesTableAll";
import Notspub from "../pages/Forms/FormRegNots";
import NotspubU from "../pages/Forms/FormRegNotsU";

import AdminTable from "../pages/Tables/AdminTable";






import Evtable from "../pages/Tables/EvTableAll";

import FormRegEvent from "../pages/Forms/FormRegEvent";
import FormRegEventU from "../pages/Forms/FormRegEventU";



import PropTable from "../pages/Tables/PropTable";

import UiButtons from "../pages/Ui/UiButtons";


import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormUploadNews from "../pages/Forms/FormUploadNews";
import FormUploadUP from "../pages/Forms/FormUploadUP";



import FormUploadU from "../pages/Forms/FormUploadU";

import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";



import FormUploadtest from "../pages/Forms/FormUploadTest";

import FormRegAdmin from "../pages/Forms/FormRegAdmin";
import FormRegAdminU from "../pages/Forms/FormRegAdminU";

import FormRegProp from "../pages/Forms/FormRegProp";
import FormRegPropU from "../pages/Forms/FormRegPropU";

import FormRegPropUC from "../pages/Forms/FormRegPropUC";


import FormPostNews from "../pages/Forms/FormPostNews";
import FormPostNewsU from "../pages/Forms/FormPostNewsU";







//Kanban Board

const authProtectedRoutes = [

	//Kanban Board
	


	{ path: "/nottable", component: Notstable },

	{ path: "/not-pub", component: Notspub },
	{ path: "/not-update/:id", component: NotspubU },




	//Utility
	{ path: "/news", component: NewsLoad },
	{ path: "/news-detail/:id", component: NewsDetail },
	{ path: "/news-update/:id", component: FormUploadNews },
	{ path: "/form-news-update/:id", component: FormPostNewsU },
	{ path: "/form-image-news/:id", component: FormUploadNews },
	{ path: "/form-news", component: FormPostNews },





	{ path: "/events/:id", component: Timeline },
	{ path: "/event-table/:id", component: Evtable },
	{ path: "/event-pub/:id", component: FormRegEvent },
	{ path: "/event-update/:id", component: FormRegEventU },


	

	//Email
	{ path: "/email-inbox", component: EmailInbox },


	{ path: "/email-isent", component: EmailIsent },

	{ path: "/email-read/:id", component: EmailRead },
	{ path: "/email-isent-read/:id", component: EmailReadSent },


	//Ecommerce
	
	{ path: "/prop-detail/:id", component: Propdetail },
	{ path: "/props", component: PropTable },



	{ path: "/dashboard/:id", component: Dashboard },

	{ path: "/admin-dashboard", component: DashboardSad },







	{ path: "/reg-admin", component: 	FormRegAdmin},

	{ path: "/up-admin/:id", component: 	FormRegAdminU},
	{ path: "/admin-list", component: AdminTable },

	{ path: "/reg-prop", component: 	FormRegProp},
	{ path: "/reg-props/:id", component: 	FormRegPropU},
	{ path: "/props-uc/:id", component: 	FormRegPropUC},

	{ path: "/form-image-upload/:id", component: FormUpload },

	{ path: "/form-image-update/:id", component: FormUploadUP },




	


{ path: "/ui-buttons", component: UiButtons },

	{ path: "/form-elements", component: FormElements },
	{ path: "/form-advanced", component: FormAdvanced },
	{ path: "/form-editors", component: FormEditors },
	{ path: "/form-mask", component: FormMask },
	{ path: "/form-file-upload-test", component: FormUploadtest },

	{ path: "/form-wizard", component: FormWizard },
	{ path: "/form-validation", component: FormValidations },
	{ path: "/form-xeditable", component: FormXeditable },


	// this route should be at the end of all other routes
];

const publicRoutes = [
	{ path: "/login", component: Login },

	{ path: "/login-test", component: Logins },

	{ path: "/login-fail", component: Loginfaild },



	// Authentication Inner


	{ path: "/maintenance", component: Maintenance },
	{ path: "/404", component: Error404 },
	{ path: "/user-exist", component: UserExist },
	{ path: "/geror", component: Geror },

	{ path: "/500", component: Error500 },



];

const Homees=[
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> },

]
export { authProtectedRoutes, publicRoutes,Homees };
