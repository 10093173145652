import { useState, useEffect } from 'react';

import countries from 'i18n-iso-countries';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function Weather() {
  // State
  const [apiData, setApiData] = useState({});
  const [getState, setGetState] = useState('erbil');
  const [state, setState] = useState('erbil');

  var today = new Date().toDateString();





  // API KEY AND URL
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${state}&appid=b3f0c9fca3028d2af76c43d2cb3369c3`;

  // Side effect
  useEffect(() => {
    fetch(apiUrl)
      .then((res) => res.json())
      .then((data) => setApiData(data));
  }, [apiUrl]);

  const inputHandler = (event) => {
    setGetState(event.target.value);
  };

  const submitHandler = () => {
    setState(getState);
  };

  const kelvinToFarenheit = (k) => {
    return (k - 273.15).toFixed(2);
  };

  return (



    

<Col lg={6}>
                    <Card>
                        <CardBody>
<div className="panel panel-white">
                                <div className="panel-heading">
                                    <h4 className="panel-title">Weather at Pavilion Erbil</h4>
                                    <div className="panel-control">
                                        <a href="#" data-toggle="tooltip" data-placement="top" title="Reload" className="panel-reload"><i className="icon-reload"></i></a>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <div className="weather-widget">
                                    {apiData.main ? (
                                     
                                        <div className="row">
                                  
                                            <div className="col-lg-12">
                                                <div className="weather-top">
                                                
                                                    <div className="weather-current pull-left ">
                                                    <img
                src={`http://openweathermap.org/img/w/${apiData.weather[0].icon}.png`}
                alt="weather status icon"
                className="weather-icon"
              />                                                        <p><span>{kelvinToFarenheit(apiData.main.temp)}&deg; C<sup></sup></span></p>
                                                    </div>
                                                    <h2 className="weather-day pull-right pull-over">Erbil, Pavilion Erbil<br /><small><b>{today}</b></small></h2>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <ul className="list-unstyled weather-info">
                                                    <li>Feels Like <span className="pull-right"><b>{kelvinToFarenheit(apiData.main.feels_like)}&deg; C</b></span></li>
                                                    <li>Condition <span className="pull-right"><b>{apiData.weather[0].description}</b></span></li>
                                                    <li>Maximum <span className="pull-right"><b>{kelvinToFarenheit(apiData.main.temp_max)}&deg; C</b></span></li>
                                                    <li>Humidity <span className="pull-right"><b>{apiData.main.humidity}%</b></span></li>

                                                </ul>
                                            </div>
                                            <div className="col-lg-6">
                                                <ul className="list-unstyled weather-info">
                                                    <li>Cloud Cover <span className="pull-right"><b>{apiData.clouds.all}%</b></span></li>
                                                <li>Pressure <span className="pull-right"><b>{apiData.main.pressure} hPa</b></span></li>

                                                    <li>Wind Speed <span className="pull-right"><b>{apiData.wind.speed} M/Sec</b></span></li>
                                                    <li>Visibility<span className="pull-right"><b>{apiData.visibility} M</b></span></li>

                                                </ul>
                                            </div>
                                            
                                                   </div> 
                                                   ):(<h1>Loading</h1>)}
                                                 
                                </div>
                                </div>

 </div>            


 </CardBody>
                    </Card>
                </Col>
   
    
  );
}

export default Weather;
