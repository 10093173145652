import React, {useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import RecentlyActivity from "./RecentlyActivity";

import Carousel from './Gallery';

import Carousel2 from './Gallery-locs';
import Weather from './weather';


function Dashboard () {


       const id=window.location.pathname;

       const answer = id.split('/');

       const path = answer[2];
        const uri=`https://adpavilion.herokuapp.com/props-listok/${path}`;


        

useEffect( () => {
    fetchItems();

},[]);
const [items, setItems] = useState({});



const fetchItems = async () => {
    const data = await fetch(uri, {      method: "GET",
    credentials: "include",
    
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Credentials": true,

    },});

    
    const items = await data.json();


    setItems(items);


  };




  const   breadcrumbItems= [
    { title : items.username, link : "/" },
    { title : "Dashboard", link : "#" },
];



const gal={img1:items.img1,img1d:items.img1_date,img2:items.img2,img2d:items.img2_date,img3:items.img3,img3d:items.img3_date,locs:items.locs}





  
    const reports = [
        { icon : "ri-home-7-line", title : "Property ID", value : items.prop_id, rate : items.time, desc : "Registered" },
        { icon : "ri-home-gear-line", title : "Property Type", value : items.proptype, rate : items.plotarea, desc : "SQM Plot Area" },
        { icon : "ri-briefcase-4-line", title : "Purchased on", value : items.purchase, rate : items.contnumb, desc : "Contract Number" },
    ];

   


 
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                        <Row>
                            <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={reports} />
                                </Row>
                                
                                {/* revenue Analytics */}
                                <RevenueAnalytics />

                            </Col>

                            <Col xl={4}>

                                {/* sales Analytics */}
                                <SalesAnalytics />

                                {/* earning reports */}
                                <EarningReports />


                            </Col>

                            <Col xl={6}>
                            <Carousel props={gal}/>

                            </Col>

                            
                            <Col xl={6}>
                            <Carousel2 props={gal}/>

                            </Col>

                        </Row>
                        

                        <Row>
                            {/* sources */}

                            <RecentlyActivity />

{/* revenue by locations */}
<Weather/>


                        </Row>

                 

                    </Container> 
                </div>
            </React.Fragment>
        );
    }

export default Dashboard;
