import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,InputGroup,  Button} from "reactstrap";
import Flatpickr from "react-flatpickr"
import { Redirect } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from "react-router-dom";

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Edit Property Event", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            idpath:"",
            eventdata:{},
            pos1:"",
            pos2:"",
            pos1v:"",
            pos2v:"",


        };
    }

componentDidMount(){

    const id=window.location.pathname;
    const answer = id.split('/');

    const path = answer[2];

    this.setState({idpath:path})
    
    const fetchuri=`https://adpavilion.herokuapp.com/event-single/${path}`;

    const options = {
        method: 'GET',
        credentials: "include",
        // If you add this, upload won't work
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // }
      };


      fetch(fetchuri, options)
      .then((response) => response.json())
      .then((data) => {
  
  
        if(data.estimd===0){
            this.setState({art:true})
        
        }else{
            this.setState({eventdata:data})
      
          }


  
  
      })

}


    render() {

        if(this.state.art === true){
            return (<Redirect to="/login" />);
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Edit Property Event " breadcrumbItems={this.state.breadcrumbItems} />
<form action={`https://adpavilion.herokuapp.com/update-event/${this.state.idpath}`} method="post">

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Please Fulfil The Following Fields</h4>
<p></p>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Event Header</Label>
                                            <Col md={10}>
                                                <Input type="text" name="evhead" defaultValue={this.state.eventdata.eventhead} maxLength="50" placeholder="Maximum Characters allowed is 50, Including Spaces" id="example-text-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Event Body</Label>
                                            <Col md={10}>
                                                <Input type="text" name="evpar" maxLength="200" defaultValue={this.state.eventdata.eventpar} placeholder="Maximum Characters allowed is 200, Including Spaces  " id="example-search-input" />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input"  className="col-md-2 col-form-label">Event Date</Label>
                                            <Col md={10}>


<InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder="yyyy-mm-dd"
    name="evdate"
    defaultValue={this.state.eventdata.eventdate}
  />
</InputGroup>

                    </Col>
                                        </Row>


                                  

                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Event Position </Label>
                                            <Col md={10}>
                                     
                                                <select className="form-select" name="evposition">
                                                    <option defaultValue={this.state.eventdata.position}>{this.state.eventdata.position}</option>
                                                    <option value="timeline-item">Right</option>
                                                    <option value="timeline-item timeline-left">Left</option>
                                                

                                                </select>
                                                <div className="attention-note">
                                                <p>Make sure to choose the correct Event position before you post the event</p>
                                                <p>you can view the Properties last event position <Link to={"/events/"+`${this.state.eventdata.owner_id}`}>here</Link></p>

                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-3">

<Col md={12}>
 
<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-md btn-block  btn-submit-block"
                      type="submit"
                    >
Post Event                    </Button>
                  </div>
</Col>

</Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </form>

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      


 <Link to="/props">
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block btn-back "
                       type="submit"
                     >
                       Go Back
                     </Button>
                   </div>
                   </Link>
                                    </CardBody>
                                </Card>
                            </Col>


                            
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
