import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,InputGroup,  Button} from "reactstrap";
import Flatpickr from "react-flatpickr"
import { Redirect } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Post News", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
        
        };
    }



 
    componentDidMount(){


      const uri=`https://adpavilion.herokuapp.com/auth-check`;



      const options = {
        method: 'GET',
        credentials: "include",
        // If you add this, upload won't work
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // }
      };


      fetch(uri, options)
      .then((response) => response.json())
      .then((data) => {
  
        if(data[0].estimd===0){
          this.setState({art:true})
      
      }
     
  
      })

  }





    render() {
  
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="News Post" breadcrumbItems={this.state.breadcrumbItems} />
<form action="https://adpavilion.herokuapp.com/news-post" method="post">



<Row>
							<Col lg="12">
								<Card>
									<CardBody>

										<h4 className="card-title">Posting a News Publication</h4>
										<p className="card-title-desc">News posts are global, that means all users will recive the news detail<code> Make sure to spell check the News detail before publishing it.</code></p>
										<Label className="form-label">News Title</Label>
								

                    <Input
											type="text"
								maxLength={100}
											name="newshead"
											id="defaultconfig"
										/>
				

        <div className="mt-3">


<Label>News Date:</Label>
<InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder="yyyy-mm-dd"
    name="newsdate"
 
  />
</InputGroup>

</div>

        
        <div className="mt-3">

        <Label className="form-label">First Paragraph</Label>
								

                <Input
                  type="textarea"
                  maxLength={500}
                  placeholder="First paragraph of the news detail, it accepts maximum 500 characters"
                  name="par1"
                  id="defaultconfig"
                  
                />
    
                    </div>


                      
        <div className="mt-3">

<Label className="form-label">Second Paragraph</Label>
        

        <Input
          type="textarea"
          maxLength={500}
          placeholder="Second paragraph of the news detail, it accepts maximum 500 characters"
          name="par2"
          id="defaultconfig"
          
        />

            </div>


              
        <div className="mt-3">

<Label className="form-label">Third Paragraph</Label>
        

        <Input
          type="textarea"
          maxLength={500}
          placeholder="Third paragraph of the news detail, it accepts maximum 500 characters"
          name="par3"
          id="defaultconfig"
          
        />

            </div>

        <div className="mt-3">

<Label className="form-label">News Tag 1</Label>
<p className="text-muted m-b-15">
Write keywords that are most frequently used in the news body										</p>

        <Input
          type="text"
          name="tag1"
          id="defaultconfig"
          maxLength="20"
            minLength="6"
          
        />

            </div>


            <div className="mt-3">

<Label className="form-label">News Tag 2</Label>

        <Input
          type="text"
          name="tag2"
          id="defaultconfig"
          maxLength="6"
            minLength="6"
          
        />

            </div>


            <div className="mt-3">

<Label className="form-label">News Tag 3</Label>

        <Input
          type="text"
          name="tag3"
          id="defaultconfig"
          maxLength="6"
            minLength="6"
          
        />

            </div>
            
       




									
									</CardBody>
								</Card>
							</Col>






              <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      

 
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block  btn-submit-block"
                       type="submit"
                     >
Proceed                     </Button>
                   </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

	
			  </Row>

              </form>




                  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
