import React, { Component } from 'react';
export default class FilesUploadComponent extends Component {
    constructor(props) {
        super(props);
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            profileImg: '',
            prop:888888
        }
    }
    onFileChange(e) {
        this.setState({ profileImg: e.target.files[0] })
    }
    onSubmit(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append('photo', this.state.profileImg)
        formData.append('prop', this.state.prop)

        console.log(this.state.profileImg)
        console.log(formData)


        const options = {
          method: 'POST',
          body: formData,
          // If you add this, upload won't work
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // }
        };
        

        fetch('https://adpavilion.herokuapp.com/upload-loc', options);

      
    }

    render() {
        return (

          <React.Fragment>
          <div className="page-content">
            
            <div className="container">
                <div className="row">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <input type="file" name="photo" onChange={this.onFileChange} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" type="submit">Upload</button>
                        </div>
                    </form>
                </div>
            </div>
            </div>

            </React.Fragment>
        )
    }
}