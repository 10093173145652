import React, { Component } from 'react';
import { Card, CardBody, Row, Col ,Button} from "reactstrap";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";

//Import Charts
import "./dashboard.scss";

class SalesAnalytics extends Component {


    constructor(props) {
        super(props);

    this.state = {
        series: [0,0,0,0,0],
        options: {
            labels: ["Structure","Exterior","Interior","Gardens","Finishing"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#0ebfe8', '#164B42', '#eeb902','#1cbb8c','#994700'],
           
        },

        datapr: {},

    }

    }




 




    render() {


        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end">
                          
                        </div>
                        <h4 className="card-title mb-4">Quick Actions</h4>

                        <div id="donut-chart" className="apex-charts">
<Card>
    <CardBody>
    <Link to="./reg-prop"><Button color="primary" type="button" className="waves-effect waves-light me-1 express-btn">
                        Register A New Property <i className="ri-user-3-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      
                          </CardBody>
</Card>                        </div>


<div id="donut-chart" className="apex-charts">
<Card>
    <CardBody>
    <Link to="./form-news"><Button color="warning" type="button" className="waves-effect waves-light me-1 express-btn">
                        Publish A News <i className="ri-newspaper-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      
                          </CardBody>
</Card>                        </div>


<div id="donut-chart" className="apex-charts">
<Card>
    <CardBody>
    <Link to="./not-pub"><Button color="warning" type="button" className="waves-effect waves-light me-1 express-btn">
                        Create a Push Notification <i className="ri-newspaper-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      
                          </CardBody>
</Card>                        </div>

<div id="donut-chart" className="apex-charts">
<Card>
    <CardBody>
    <Link to="./email-inbox"><Button color="warning" type="button" className="waves-effect waves-light me-1 express-btn">
                        Go to Pavilion Erbil Inbox <i className="ri-newspaper-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      
                          </CardBody>
</Card>                        </div>

<div id="donut-chart" className="apex-charts">
<Card>
    <CardBody>
    <Link to="./admin-list"><Button color="danger" type="button" className="waves-effect waves-light me-1 express-btn">
                        Review System Admins  <i className="ri-newspaper-line align-middle ms-2"></i>
                      </Button>
                      </Link>
                      
                          </CardBody>
</Card>                        </div>

                  
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;