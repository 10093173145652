import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,InputGroup,  Button} from "reactstrap";
import { Redirect } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "Update Property Creds", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            items:{},
            idpath:""
        
        };
    }



    componentDidMount(){

      const id=window.location.pathname;

       const answer = id.split('/');

       const path = answer[2];
        const uri=`https://adpavilion.herokuapp.com/props-uc/${path}`;

        this.setState({idpath:path})


        const options = {
          method: 'GET',
          credentials: "include",
          // If you add this, upload won't work
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // }
        };


        fetch(uri, options)
        .then((response) => response.json())
        .then((data) => {
    
    
          if(data.estimd===0){
            this.setState({art:true})
        
        }else{
            this.setState({items:data})
      
          }

      
    
    
    
    
        })

    }






    render() {
      if(this.state.art === true){
        return (<Redirect to="/login" />);
    }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Master Property Creds" breadcrumbItems={this.state.breadcrumbItems} />
<form action="https://adpavilion.herokuapp.com/user-update" method="post">



<Row>
							<Col lg="12">
								<Card>
									<CardBody>

										<h4 className="card-title">Property Owner Credentials</h4>
										<p className="card-title-desc">You can modify Property Owner's credentials <code>Don't Forget to note down the new password and to notify the user via SMS or Phone Call</code></p>
										<Label className="form-label">System ID</Label>
								

                    <Input
											type="text"
								
defaultValue={this.state.idpath}
											name="idofuser"
											id="defaultconfig"
                      readOnly
										/>
				

      
        
        <div className="mt-3">

        <Label className="form-label">Last Updated</Label>
								

                <Input
                  type="text"
defaultValue={this.state.items.lastup}
                  name="lastup"
                  id="defaultconfig"
                  readOnly
                />
    
                    </div>

        <div className="mt-3">

<Label className="form-label">Property ID</Label>
<p className="text-muted m-b-15">
										The Property ID is the most important field, it accepts a minimum Value of 6 digits :
										</p>

        <Input
          type="text"
defaultValue={this.state.items.prop_id}
          name="pr_id"
          id="defaultconfig"
          maxLength="6"
            minLength="6"
          
        />

            </div>


            
        <div className="mt-3">

<Label className="form-label">Username</Label>
        
<p className="text-muted m-b-15">
												Input the Property Owners Email Address
											</p>
        <Input
          type="text"
defaultValue={this.state.items.username}
          name="uname"
          id="defaultconfig"
        />

            </div>

            <div className="mt-3">

<Label className="form-label">Password</Label>
        
<p className="text-muted m-b-15">
												<code>Leave blank if you don't want to modify the owners password, </code>Otherwise please Generate a password consisting of minimum 8 characters, preferably use capital and small letters with numbers 
												and make sure to <code>note down the password</code>:
											</p>
        <Input
          type="text"
          placeholder="Accepts Minimum 8 Characters, Leave blank if you don't want to modify the Property owner's password"
          minLength={8}
          name="pw"
          id="defaultconfig"
        />

            </div>





									
									</CardBody>
								</Card>
							</Col>






              <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      


 
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block  btn-submit-block"
                       type="submit"
                     >
                       Update Credentials
                     </Button>
                   </div>
                                    </CardBody>
                                </Card>
                            </Col>


                            
                        </Row>

	
			  </Row>

              </form>



              <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                      


 <Link to="/props">
                    <div className="d-grid">
                     <Button
                       color="light"
                       className="btn btn-light btn-md btn-block btn-back "
                       type="submit"
                     >
                       Go Back
                     </Button>
                   </div>
                   </Link>
                                    </CardBody>
                                </Card>
                            </Col>


                            
                        </Row>

	
                  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
