import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input,InputGroup,  Button} from "reactstrap";
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Super Admin", link: "#" },
                { title: "New Property Event", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            idpath:""
        };
    }

componentDidMount(){

    const id=window.location.pathname;
    const answer = id.split('/');

    const path = answer[2];

    this.setState({idpath:path})


}


    render() {

        console.log(this.state.idpath)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="New Property Event " breadcrumbItems={this.state.breadcrumbItems} />
<form action={`https://adpavilion.herokuapp.com/event-post/${this.state.idpath}`} method="post">

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Please Fulfil The Following Fields</h4>
<p></p>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Event Header</Label>
                                            <Col md={10}>
                                                <Input type="text" name="evhead" maxLength="50" placeholder="Maximum Characters allowed is 50, Including Spaces" id="example-text-input" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Event Body</Label>
                                            <Col md={10}>
                                                <Input type="text" name="evpar" maxLength="200" placeholder="Maximum Characters allowed is 200, Including Spaces  " id="example-search-input" />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Event Date</Label>
                                            <Col md={10}>


<InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder="yyyy-mm-dd"
    name="evdate"
 
  />
</InputGroup>

                    </Col>
                                        </Row>


                                  

                                        <Row className="mb-3">

                                            <Label className="col-md-2 col-form-label">Event Position </Label>
                                            <Col md={10}>
                                     
                                                <select className="form-select" name="evposition">
                                                    <option defaultValue value="timeline-item timeline-left">Left</option>
                                                    <option value="timeline-item">Right</option>
       
                                                </select>
                                                <div className="attention-note">
                                                <p>Push Notification are global and All users will be able to view them, Make sure you spell check everything before pushing the notification</p>
                                                <p>Date and time of the push notification is registered automatically and is set to the time of publishing</p>

                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-3">

<Col md={12}>
 
<div className="d-grid">
                    <Button
                      color="light"
                      className="btn btn-light btn-sm btn-block  btn-submit-block"
                      type="submit"
                    >
Post Event                    </Button>
                  </div>
</Col>

</Row>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </form>

                  
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
